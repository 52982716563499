/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/theming';

$app-typography: mat-typography-config(
  $font-family: 'Roboto, monospace',
  $display-4: mat-typography-level(112px, 112px, 300),
  $display-3: mat-typography-level(56px, 56px, 400),
  $display-2: mat-typography-level(45px, 48px, 400),
  $display-1: mat-typography-level(34px, 40px, 400),
  $headline: mat-typography-level(26px, 32px, 300),
  $title: mat-typography-level(20px, 32px, 300),
  $subheading-2: mat-typography-level(16px, 28px, 400),
  $subheading-1: mat-typography-level(15px, 24px, 400),
  $body-2: mat-typography-level(16px, 24px, 300),
  $body-1: mat-typography-level(16px, 20px, 300),
  $caption: mat-typography-level(12px, 20px, 400),
  $button: mat-typography-level(14px, 14px, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat-typography-level(16px, 1.25, 400),
);

@include mat-core($app-typography);

// @import "./theme.scss";
// @import "./custom-component-themes.scss";

// @include angular-material-theme($theming-material-components-theme);
// @include custom-components-theme($theming-material-components-theme);

// .dark-theme {
//   @include angular-material-color($dark-theme);
// }

// @include angular-material-typography($typography);

body,
html {
  margin: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #101518;
  font-size: mat-font-size($app-typography, body-1);
  font-family: mat-font-family($app-typography);
  line-height: mat-line-height($app-typography, body-1);
}

.accent {
  color: #ffca28;
}
